import React, { useEffect, useRef, useState } from 'react';
import ShareButton from '../components/ShareButton';

import manPng from '../assets/man.png';

const Test: React.FC<{}> = () => {
	const mountedCnt = useRef(0);

	const [files, setFiles] = useState<File[]>([]);

	useEffect(() => {
		mountedCnt.current++;
		console.log('mounted count', mountedCnt.current);
		fetch(manPng)
			.then((res) => res.blob())
			.then((blob) => {
				if (blob) {
					const f = new File([blob], 'man.png', { type: 'image/png' });
					setFiles([f]);
				}
			});
	}, []);

	return (
		<div>
			<ShareButton
				shareData={{
					title: '보낼 제목',
					text: '보낼 내용',
					url: 'test',
					files // 첨부파일
				}}
			/>
			<hr />
		</div>
	);
};

export default React.memo(Test);
