import { CarModel } from '../firebase/app';
import { DeepValueOf, Nullable } from '../types/utils';

export type State = {
	initedApp: boolean;
	history: string[];
	formData: {
		manufacturerId: string;
		carModelId: string;
		regionId: string;
		sigunguId: string;
	};
	textData: {
		manufacturer: string;
		carModel: string;
		region: string;
		sigungu: string;
		category: string;
	};
	selctedCarModel: Nullable<CarModel>;
	screenDiff: number;
};

export const initialState: State = {
	initedApp: false,
	history: JSON.parse(localStorage.getItem('history') ?? '[]'),
	formData: JSON.parse(
		localStorage.getItem('formData') ??
			`{ "manufacturerId": "", "carModelId": "", "regionId": "", "sigunguId": "" }`
	),
	textData: JSON.parse(
		localStorage.getItem('textData') ??
			`{ "manufacturer": "", "category": "", "carModel": "", "region": "", "sigungu": "" }`
	),
	selctedCarModel: null,
	screenDiff: 0
};

export const actionTypes = {
	app: {
		init: 'app/init'
	},
	history: {
		add: 'history/add',
		clear: 'history/clear',
		onback: 'history/onback'
	},
	formData: {
		set: 'formdata/set',
		clear: 'formdata/clear',
		setManufacturerId: 'formdata/set-manufacturer-id',
		setCarModelId: 'formdata/set-carmodel-id',
		setRegionId: 'formdata/set-region-id',
		setSigunguId: 'formdata/set-sigungu-id'
	},
	textData: {
		set: 'textdata/set',
		clear: 'textdata/clear',
		setManufacturer: 'textdata/set-manufacturer',
		setCarModel: 'textdata/set-carmodel',
		setCategory: 'textdata/set-category',
		setRegion: 'textdata/set-region',
		setSigungu: 'textdata/set-sigungu'
	},
	selectedCarModel: {
		get: 'selected-car-model/get',
		set: 'selected-car-model/set'
	},
	screendDiff: {
		set: 'screenDiff/set'
	}
} as const;

export type Action = {
	type: DeepValueOf<typeof actionTypes>;
	payload: string;
};

export default function reducer(state: State, action: Action): State {
	let _state = state;
	switch (action.type) {
		case 'app/init':
			_state = {
				...state,
				initedApp: true
			};
			break;

		case 'history/add':
			_state = {
				...state,
				history: [...state.history, action.payload]
			};
			break;
		case 'history/clear':
			_state = {
				...state,
				history: []
			};
			break;
		case 'history/onback':
			_state = {
				...state,
				history: [...state.history.slice(0, state.history.length - 2)]
			};
			break;

		case 'formdata/clear':
			try {
				_state = {
					...state,
					formData: {
						regionId: '',
						sigunguId: '',
						manufacturerId: '',
						carModelId: ''
					}
				};
			} catch (e) {
				_state = state;
			}
			break;

		case 'formdata/set':
			try {
				const formData = JSON.parse(action.payload);
				_state = {
					...state,
					formData: formData
				};
			} catch (e) {
				_state = state;
			}
			break;

		case 'formdata/set-manufacturer-id':
			_state = {
				...state,
				formData: {
					...state.formData,
					manufacturerId: action.payload
				}
			};
			break;
		case 'formdata/set-carmodel-id':
			_state = {
				...state,
				formData: {
					...state.formData,
					carModelId: action.payload
				}
			};
			break;
		case 'formdata/set-region-id':
			_state = {
				...state,
				formData: {
					...state.formData,
					regionId: action.payload
				}
			};
			break;
		case 'formdata/set-sigungu-id':
			_state = {
				...state,
				formData: {
					...state.formData,
					sigunguId: action.payload
				}
			};
			break;

		case 'textdata/clear':
			try {
				_state = {
					...state,
					textData: {
						region: '',
						sigungu: '',
						manufacturer: '',
						carModel: '',
						category: ''
					}
				};
			} catch (e) {
				_state = state;
			}
			break;

		case 'textdata/set':
			try {
				const textData = JSON.parse(action.payload);
				_state = {
					...state,
					textData
				};
			} catch (e) {
				_state = state;
			}
			break;

		case 'textdata/set-manufacturer':
			_state = {
				...state,
				textData: {
					...state.textData,
					manufacturer: action.payload
				}
			};
			break;

		case 'textdata/set-carmodel':
			_state = {
				...state,
				textData: {
					...state.textData,
					carModel: action.payload
				}
			};
			break;

		case 'textdata/set-category':
			_state = {
				...state,
				textData: {
					...state.textData,
					category: action.payload
				}
			};
			break;

		case 'textdata/set-region':
			_state = {
				...state,
				textData: {
					...state.textData,
					region: action.payload
				}
			};
			break;
		case 'textdata/set-sigungu':
			_state = {
				...state,
				textData: {
					...state.textData,
					sigungu: action.payload
				}
			};
			break;
		case 'selected-car-model/get':
			const carModelStr = localStorage.getItem('selectedCarModel');
			if (carModelStr) {
				_state = {
					...state,
					selctedCarModel: JSON.parse(carModelStr)
				};
			}
			break;
		case 'selected-car-model/set':
			try {
				const carModel = JSON.parse(action.payload) as CarModel;
				_state = {
					...state,
					selctedCarModel: carModel
				};
				localStorage.setItem('selectedCarModel', action.payload);
			} catch (error) {}
			break;
		case 'screenDiff/set':
			const num = parseInt(action.payload);
			if (isNaN(num)) throw Error('module error');
			_state = {
				...state,
				screenDiff: num
			};
			break;
	}

	localStorage.setItem('history', JSON.stringify(_state.history));
	localStorage.setItem('formData', JSON.stringify(_state.formData));
	localStorage.setItem('textData', JSON.stringify(_state.textData));
	return _state;
}
