import React from 'react';
import styled from 'styled-components';
import { Box, CardWrapper, FlexItem, HBox, Text } from './common';

const Img = styled.img`
	width: 70px;
	height: 70px;
`;

const ImgBox = styled(Box)`
	position: absolute;
	width: 80px;
`;

const ItemWrapper = styled(HBox)`
	position: relative;
	justify-content: center;
	align-items: center;
`;

const ImageContainer: React.FC<{
	imageSrc: string;
	imageAlt: string;
	imageAlign: string;
}> = (props) => (
	<ImgBox style={props.imageAlign == 'left' ? { left: -20 } : { right: -20 }}>
		<Img src={props.imageSrc} alt={props.imageAlt} />
	</ImgBox>
);

const SplashCard: React.FC<
	React.PropsWithChildren<{
		imageSrc: string;
		imageAlign: string;
		imageAlt: string;
	}>
> = (props) => {
	return (
		<FlexItem $mt={2}>
			<ItemWrapper>
				<ImageContainer
					imageSrc={props.imageSrc}
					imageAlt={props.imageAlt}
					imageAlign={props.imageAlign}
				/>
				<CardWrapper
					$height="60px"
					$radius={60}
					$mb={4}
					$px={16}
					$mx={10}
					style={
						props.imageAlign == 'right'
							? { paddingRight: '50px' }
							: { paddingLeft: '50px' }
					}
				>
					<Text $variant="p2" $lineHeight={1.2} $textAlign="left">
						{props.children}
					</Text>
				</CardWrapper>
			</ItemWrapper>
		</FlexItem>
	);
};

export default SplashCard;
