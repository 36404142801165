const Colors = {
	/** --green-01 :  */
	primary01: '#50A060',
	/** --green-02 :  */
	primary02: '#E9F2A7',
	/** --green-03 :  */
	primary03: '#DDF291',
	/** --green-04 :  */
	primary04: '#cff09e',
	/** darkgreen :  */
	primary05: '#2F400A',
	/** --orange--:  */
	primary06: '#FFBC42',

	/** --lightpurple  */
	secondary01: '#D9D5F2',
	/** --navyBlue */
	secondary02: '#1D1B59',
	/** --lightyellow */
	secondary03: '#FFE194',
	/** --lightgreen */
	secondary04: '#D9FCDB',
	/** --lightOrange */
	secondary05: '#FED2AA',
	/** --Orange  */
	secondary06: '#FFC286',
	/** --lightyellow02 */
	secondary07: '#FFF1AF',

	/** --black-01 */
	dark: '#212126',
	/** --black-02 */
	dark02: '#454545',
	/** --blue-01 */
	blue01: '#1B8EF2',
	blue02: '#63B0F2',
	blue03: '#D0E2F2',

	/** --red-01 */
	red01: '#FC5404',
	/** --gray-01 */
	font: '#8D96A1',
	/** --lightgray */
	lightFont: '#F0F2F1',

	transparent: 'rgba(0,0,0,0)'
} as const;

const Spaces = {
	xxs: 4,
	xs: 8,
	ss: 12,
	s: 16,
	sm: 20,
	m: 24,
	ml: 28,
	l: 32,
	xl: 40
} as const;

const TextSizes = {
	h1: '48px',
	h2: '42px',
	h3: '36px',
	h4: '30px',
	h5: '24px',
	h6: '18px',
	p1: '18px',
	p2: '16px',
	p3: '14px',
	p4: '12px',
	span: '14px'
} as const;

const Common = {
	appMaxWidth: '460px',
	appPadding: '16px'
} as const;

export type ColorsType = typeof Colors;
export type SpacesType = typeof Spaces;
export type TextSizesType = typeof TextSizes;
export type CommonType = typeof Common;

export const Theme = {
	Colors,
	Spaces,
	TextSizes,
	Common
} as const;
