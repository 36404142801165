import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { Sigungu } from '../../interfaces/Sigungu';
import { equalTo, orderByChild, query } from 'firebase/database';
import { toArray } from '../../utils';

export function useSigungus(
	region_id: number
): UseQueryResult<Sigungu[], unknown> {
	return useQuery(
		['sigungu', region_id],
		async () =>
			await fetchData<Record<string, Sigungu> | null>('/sigungus', (ref) => {
				return query(ref, orderByChild('region_id'), equalTo(region_id));
			})
				.then((values) => values ?? {})
				.then((values) => toArray<Sigungu>(values)),
		{
			enabled: !!region_id
		}
	);
}
