import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { Region } from '../../interfaces/Region';
import { toArray } from '../../utils';

export function useRegions(): UseQueryResult<Region[], unknown> {
	return useQuery({
		queryKey: ['regions'],
		queryFn: async () =>
			await fetchData<Record<string, Region> | null>('/regions')
				.then((values) => values ?? {})
				.then((values) => toArray<Region>(values))
	});
}
