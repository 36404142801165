import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { DisplayCarModel } from '../../interfaces/DisplayCarModel';
import { equalTo, orderByChild, query } from 'firebase/database';
import { toArray } from '../../utils';

export function useDisplayCarModel(
	model_id: number
): UseQueryResult<DisplayCarModel, unknown> {
	return useQuery({
		queryKey: ['display_car_model', model_id],
		queryFn: async () =>
			await fetchData<Record<string, DisplayCarModel> | null>(
				'/display_car_models',
				(ref) => {
					return query(
						ref,
						orderByChild('display_model_id'),
						equalTo(model_id)
					);
				}
			)
				.then((values) => values ?? {})
				.then((values) => toArray<DisplayCarModel>(values))
				.then((values) => (values ? values[0] : null))
	});
}
