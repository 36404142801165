// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref, query } from 'firebase/database';
import type { Query, DatabaseReference } from 'firebase/database';
import { BaseJSON } from '../types/common';
import { Nullable } from '../types/utils';
import { getDateStr } from '../utils';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyDS_8YVoRzQO-79JMhLjlZECoCt9nlRtwQ',
	authDomain: 'evcaro-v2-db2.firebaseapp.com',
	databaseURL:
		'https://evcaro-v2-db2-default-rtdb.asia-southeast1.firebasedatabase.app',
	projectId: 'evcaro-v2-db2',
	storageBucket: 'evcaro-v2-db2.appspot.com',
	messagingSenderId: '221834744259',
	appId: '1:221834744259:web:2c64caa16ed3598617ed76',
	measurementId: 'G-D8GZVWB60J'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const _analytics = getAnalytics(app);

export default app;

export const database = getDatabase(app);

type GetQuery = (ref: DatabaseReference) => Query;
export function fetchData<T>(path: string, getQuery?: GetQuery): Promise<T> {
	const dbRef = ref(database, path);
	const q = getQuery ? getQuery(dbRef) : query(dbRef);
	return new Promise<T>((resolve, reject) => {
		onValue(
			q,
			(snapshot) => {
				const data = snapshot.val();
				resolve(data as T);
			},
			(err) => {
				reject(err);
			}
		);
	});
}

type GetData = <T>(uri: string) => Promise<T>;
export const getData: GetData = <T>(uri: string) => {
	const regionListRef = ref(database, uri);

	return new Promise<T>((resolve, _reject) => {
		onValue(regionListRef, (snapshot) => {
			const data = snapshot.val();
			resolve(data as T);
		});
	});
};

export type GetDataFunc<T, P = undefined> = P extends {}
	? (p: P) => Promise<T>
	: () => Promise<T>;

export type Region = BaseJSON;
type GetRegionList = GetDataFunc<Region[]>;
export const getRegionList: GetRegionList = async () =>
	(await getData<Nullable<Region[]>>('/static/region_list')) ?? [];

export type Sigungu = BaseJSON;
type GetSigunguList = GetDataFunc<
	Sigungu[],
	{
		regionId: number;
	}
>;
export const getSigunguList: GetSigunguList = async (p) =>
	(await getData<Nullable<Sigungu[]>>(`/static/sigungus/${p.regionId}`)) ?? [];

export type ManufactureCompany = BaseJSON;
type GetManufactureCompanyList = GetDataFunc<ManufactureCompany[]>;
export const getManufactureCompanyList: GetManufactureCompanyList = async () =>
	(await getData<Nullable<Sigungu[]>>(`/static/manufacture_company_list`)) ??
	[];

export type CarModel = {
	id: string;
	category: string;
	name: string;
};
type GetCarModelList = GetDataFunc<
	CarModel[],
	{
		manufactureCompanyId: number;
	}
>;
export const getCarModelList: GetCarModelList = async (p) =>
	(await getData<Nullable<CarModel[]>>(
		`/static/car_models/${p.manufactureCompanyId}`
	)) ?? [];

export type ContactData = {
	department: string;
	tel: string;
	memo: string;
};
type GetContactData = GetDataFunc<
	Partial<ContactData>,
	{
		sigunguId: number;
	}
>;
export const getContactData: GetContactData = async (p) =>
	(await getData<Nullable<ContactData>>(
		`/static/ev_contact_data/${p.sigunguId}`
	)) ?? {};

export type CarModelPriceData = {
	stateMoney: number;
	regionMoney: number;
	// subsidy: number;
};
type GetCarModelPriceData = GetDataFunc<
	Partial<CarModelPriceData>,
	{
		sigunguId: number;
		carModelId: number;
	}
>;
export const getCarModelPriceData: GetCarModelPriceData = async (p) =>
	(await getData<Nullable<CarModelPriceData>>(
		`/static/ev_car_model_price_data/${p.sigunguId}/${p.carModelId}`
	)) ?? {};

export type EvLocalInfoData = {
	announcedFirst: number;
	announcedOfficial: number;
	announcedTaxi: number;
	announcedLast: number;
};

type GetEvLocalInfoData = GetDataFunc<
	Partial<EvLocalInfoData>,
	{
		sigunguId: number;
	}
>;
export const getEvLocalInfoData: GetEvLocalInfoData = async (p) =>
	(await getData<Nullable<EvLocalInfoData>>(
		`/static/ev_info_data/${p.sigunguId}`
	)) ?? {};

export type EvLocalInfoDailyData = {
	appliedFirst: number;
	appliedOfficial: number;
	appliedTaxi: number;
	appliedLast: number;

	relasedFirst: number;
	relasedOfficial: number;
	relasedTaxi: number;
	relasedLast: number;

	// remainedFirst: number;
	// remainedOfficial: number;
	// remainedTaxi: number;
	// remainedLast: number;
};

type GetEvLocalInfoTodayData = GetDataFunc<
	Partial<EvLocalInfoDailyData>,
	{
		sigunguId: number;
	}
>;
export const getEvLocalInfoTodayData: GetEvLocalInfoTodayData = async (p) => {
	const d = new Date();
	const today = getDateStr(d);

	return (
		(await getData<Nullable<EvLocalInfoData>>(
			`/daily/${today}/ev_info_data/${p.sigunguId}`
		)) ?? {}
	);
};

type GetTodaySavedTimeStr = GetDataFunc<string>;
export const getTodaySavedTimeStr: GetTodaySavedTimeStr = async () => {
	const d = new Date();
	const today = getDateStr(d);
	return (await getData<Nullable<string>>(`/daily/${today}/saved_time`)) ?? '';
};

type GetEvLocalInfoYesterdayData = GetDataFunc<
	Partial<EvLocalInfoDailyData>,
	{
		sigunguId: number;
	}
>;
export const getEvLocalInfoYesterdayData: GetEvLocalInfoYesterdayData = async (
	p
) => {
	const d = new Date();
	d.setDate(d.getDate() - 1);
	const yesterday = getDateStr(d);

	return (
		(await getData<Nullable<EvLocalInfoData>>(
			`/daily/${yesterday}/ev_info_data/${p.sigunguId}`
		)) ?? {}
	);
};

export type CarnoonPriceData = {
	info: string;
	name: string;
	price: number;
	subTitle: string;
	subsidyPrice: number;
	subsidyPriceMemo: string;
};

export type CarnoonModel = {
	id: number;
	title: string;
	type: string;
	datas: CarnoonPriceData[];
};

type GetCarnoonModelsData = GetDataFunc<
	CarnoonModel[],
	{
		manufactureCompanyId: number;
	}
>;
export const getCarnoonModelsData: GetCarnoonModelsData = async (p) => {
	return (
		(await getData<CarnoonModel[]>(
			`/static/carnoon/models/${p.manufactureCompanyId}`
		)) ?? {}
	);
};

export type ExcelModelData = {
	id: string;
	manufacturer: string;
	category: string;
	evModelName: string;
	name: string;
};

type GetExcelModalList = GetDataFunc<
	ExcelModelData[],
	{
		manufactureCompanyId: string;
	}
>;
export const getExcelModelList: GetExcelModalList = async (p) => {
	return (
		(await getData<ExcelModelData[]>(
			`/static/excel_data/models/${p.manufactureCompanyId}`
		)) ?? {}
	);
};

export type ExcelPriceData = {
	name: string;
	price: number;
};

type GetExcelPriceList = GetDataFunc<
	ExcelPriceData[],
	{
		excelModelId: string;
	}
>;
export const getExcelPriceList: GetExcelPriceList = async (p) => {
	return (
		(await getData<ExcelPriceData[]>(
			`/static/excel_data/price/${p.excelModelId}`
		)) ?? {}
	);
};

export type ExcelManufactureCompany = BaseJSON;
type GetExcelManufactureCompanyList = GetDataFunc<ExcelManufactureCompany[]>;
export const getExcelManufactureCompanyList: GetExcelManufactureCompanyList =
	async () =>
		(await getData<Nullable<Sigungu[]>>(
			`/static/excel_data/manufacture_company_list`
		)) ?? [];

export type GetCoupangDeeplink = GetDataFunc<string, { modelId: string }>;
export const getCoupangDeeplink: GetCoupangDeeplink = async (p) =>
	(await getData<Nullable<string>>(
		`/static/coupang/deeplinks/${p.modelId}/landingUrl`
	)) ?? '';
