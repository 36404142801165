import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Box,
	FlexItem,
	HBox,
	HeadLine,
	Label,
	SubHeadLine,
	Text,
	VBox
} from '../components/common';
import Layout from '../components/Layout';
import Select, { OnChangeSelect, OptionType } from '../components/Select';
import { useRegions } from '../hooks/queries/useRegions';
import { useSigungus } from '../hooks/queries/useSigungus';

const getDefaultRegion = () => {
	const usp = new URLSearchParams(location.search);
	return usp.get('region_id') ?? '';
};

const getDefaultSigungu = () => {
	const usp = new URLSearchParams(location.search);
	return usp.get('sigungu_id') ?? '';
};

const RegionSelect: React.FC<{}> = (_) => {
	const location = useLocation();
	const navigate = useNavigate();

	const urlSearchParams = useMemo(() => {
		const { search } = location;
		return new URLSearchParams(search);
	}, [location]);

	const { data: regions, isLoading: isLoadingRegions } = useRegions();

	const regionsOptions = useMemo<OptionType[]>(() => {
		if (isLoadingRegions) return [];
		if (!regions) return [];

		return regions.map((region) => ({
			value: `${region.region_id}`,
			name: region.region_name
		})) as OptionType[];
	}, [regions, isLoadingRegions]);

	const [selectedRegion, setSelectedRegion] = useState<string>(
		getDefaultRegion()
	);

	const { data: sigungus, isLoading: isLoadingSigungus } = useSigungus(
		parseInt(selectedRegion)
	);

	const sigunguOptions = useMemo<OptionType[]>(() => {
		if (isLoadingSigungus) return [];
		if (!sigungus) return [];

		return sigungus.map((sigungu) => ({
			value: `${sigungu.sigungu_id}`,
			name: sigungu.sigungu_name
		})) as OptionType[];
	}, [sigungus, isLoadingSigungus]);

	const [selectedSigungu, setSelectedSigungu] = useState<string>(
		getDefaultSigungu()
	);

	const [inited, setInited] = useState(false);

	const handleChangeRegion: OnChangeSelect = (val) => {
		setSelectedRegion(val);
		if (inited) {
			setSelectedSigungu('');
		}
	};

	useEffect(() => {
		const { pathname, search } = location;
		const urlSearchParams = new URLSearchParams(search);

		urlSearchParams.set('region_id', selectedRegion);

		navigate(`${pathname}?${urlSearchParams.toString()}`, {
			replace: true
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRegion]);

	const handleChangeSigungu: OnChangeSelect = (val) => {
		setSelectedSigungu(val);
	};

	useEffect(() => {
		const { pathname, search } = location;
		const urlSearchParams = new URLSearchParams(search);

		urlSearchParams.set('sigungu_id', selectedSigungu);

		navigate(`${pathname}?${urlSearchParams.toString()}`, {
			replace: true
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSigungu]);

	useEffect(() => {
		if ((regionsOptions.length > 0, sigunguOptions.length > 0)) {
			setInited(true);
		}

		if (getDefaultSigungu() === '' && getDefaultRegion() === '') {
			setInited(true);
		}
	}, [regionsOptions, sigunguOptions]);

	return (
		<Layout
			transition
			footerButtonOptions={{
				gaId: 'btn-region-start',
				to: `/result?${urlSearchParams.toString()}`,
				text: '조회하기',
				disabled: !selectedSigungu
			}}
		>
			<VBox $py={16} $px={25}>
				<FlexItem>
					<Box $mt={20}>
						<HeadLine $variant="h5" $mb={12}>
							<Text $variant="p2">📍</Text> 지역을 선택해주세요.
						</HeadLine>
						<SubHeadLine $variant="p2">
							시/도 를 선택하고, 지역을 선택해주세요.
						</SubHeadLine>
						<HBox $mt={30}>
							<FlexItem $flex="1 1 40%">
								<VBox $mr={16}>
									<Label $mb={8}>시/도</Label>
									<Select
										defaultValue={getDefaultRegion()}
										options={regionsOptions}
										onChange={handleChangeRegion}
									></Select>
								</VBox>
							</FlexItem>
							<FlexItem $flex="1 1 40%">
								<VBox>
									<Label $mb={8}>지역(시/군/구)</Label>
									<Select
										defaultValue={getDefaultSigungu()}
										options={sigunguOptions}
										onChange={handleChangeSigungu}
									></Select>
								</VBox>
							</FlexItem>
						</HBox>
					</Box>
				</FlexItem>
			</VBox>
		</Layout>
	);
};

export default RegionSelect;
