import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { equalTo, orderByChild, query } from 'firebase/database';
import { Subsidy } from '../../interfaces/Subsidy';
import { toArray } from '../../utils';

export function useSubsidy(
	sigungu_id: number,
	model_id: number
): UseQueryResult<Subsidy | null, unknown> {
	return useQuery({
		queryKey: ['subsidy', sigungu_id, model_id],
		queryFn: async () =>
			await fetchData<Record<string, Subsidy> | null>('/subsidies', (ref) => {
				return query(
					ref,
					orderByChild('sigungu_id_dot_display_model_id'),
					equalTo(`_${sigungu_id}.${model_id}`)
				);
			})
				.then((values) => values ?? {})
				.then((values) => toArray<Subsidy>(values))
				.then((values) => (values ? values[0] : null))
	});
}
