/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import evcar from '../assets/evbluecar.png';
import { Box, HBox, Text, VBox } from '../components/common';
import { getCoupangDeeplink } from '../firebase/app';
import useStore from '../hooks/useStore';

const TextWrapper = styled.div`
	font-size: 10px;
	text-align: center;
	color: ${(props) => props.theme.Colors.font};
`;

const Banner = styled(HBox)`
	width: calc(100% - 16px);
	height: 50px;
	padding: 10px;
	justify-content: space-around;
	align-items: center;
	border-radius: 8px;
	font-size: 16px;
	font-weight: 700;
	background-color: ${(props) => props.theme.Colors.blue03};
	cursor: pointer;
`;

const SmTitle = styled(Text)`
	margin-top: 4px;
	font-size: 16px;
	font-weight: 500;
	color: ${(props) => props.theme.Colors.blue01};
`;

const LgTitle = styled(Text)`
	margin-top: 4px;
	font-size: 16px;
	font-weight: 700;
	color: ${(props) => props.theme.Colors.blue01};
`;

const Icon = styled.img`
	width: 50px;
`;

const defaultDeeplink = {
	originalUrl:
		'https://www.coupang.com/np/search?component=183960&q=%EC%B0%A8%EC%95%85%EC%84%B8%EC%84%9C%EB%A6%AC&channel=user',
	shortenUrl: 'https://link.coupang.com/a/I1Igr',
	landingUrl:
		'https://link.coupang.com/re/AFFSRP?lptag=AF2727063&pageKey=%EC%B0%A8%EC%95%85%EC%84%B8%EC%84%9C%EB%A6%AC&traceid=V0-183-373524a89ed2cbfe'
};

const CoupangAds: React.FC<{}> = (_) => {
	const { state } = useStore();
	const [link, setLink] = useState<string>('');

	useEffect(() => {
		(async () => {
			try {
				if (state.selctedCarModel) {
					const deeplink = await getCoupangDeeplink({
						modelId: state.selctedCarModel.id
					});
					setLink(deeplink);
				} else {
					throw Error('not found link');
				}
			} catch (err) {
				// pass
				setLink(defaultDeeplink.landingUrl);
			}
		})();
	}, [state]);

	const bnrUrl = () => {
		if (link) {
			window.open(link + '&subid=evcaroAD');
			// location.href = link;
		}
	};

	return (
		<Box $height="85px">
			<Box>
				<Banner onClick={bnrUrl}>
					<VBox>
						<SmTitle>인기상품만 다 모았다</SmTitle>
						<LgTitle>
							{state.selctedCarModel?.category ?? ''} 차량용품 미리 준비하자!
						</LgTitle>
					</VBox>
					<Icon src={evcar} alt="전기차 아이콘" />
				</Banner>
				<TextWrapper>
					이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를
					제공받습니다.
				</TextWrapper>
			</Box>
		</Box>
	);
};
export default CoupangAds;
