import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { Contact } from '../../interfaces/Contact';
import { equalTo, orderByChild, query } from 'firebase/database';
import { toArray } from '../../utils';

export function useContact(
	sigungu_id: number
): UseQueryResult<Contact | null, unknown> {
	return useQuery({
		queryKey: ['contacts', sigungu_id],
		queryFn: async () =>
			await fetchData<Record<string, Contact> | null>('/contacts', (ref) => {
				return query(ref, orderByChild('sigungu_id'), equalTo(sigungu_id));
			})
				.then((values) => values ?? {})
				.then((values) => toArray<Contact>(values))
				.then((values) => (values ? values[0] : null))
	});
}
