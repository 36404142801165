import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { equalTo, limitToLast, orderByChild, query } from 'firebase/database';
import { InfoData } from '../../interfaces/InfoData';
import { toArray } from '../../utils';
import moment from 'moment';

export function useYesterdayInfoData(
	sigungu_id: number
): UseQueryResult<InfoData | null, unknown> {
	const yesterday = moment().add(-1, 'day').format('YYYYMMDD');
	const searchKey = `_${yesterday}.${sigungu_id}`;
	return useQuery({
		queryKey: ['yesterday_info_data', searchKey],
		queryFn: async () =>
			await fetchData<Record<string, InfoData> | null>('/info_data', (ref) => {
				return query(
					ref,
					orderByChild('date_yyyymmdd_dot_sigungu_id'),
					equalTo(searchKey),
					limitToLast(1)
				);
			})
				.then((values) => values ?? {})
				.then((values) => toArray<InfoData>(values))
				.then((values) => (values ? values[0] : null))
	});
}
