import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import useStore from '../hooks/useStore';
import {
	BlurBox,
	Box,
	Button,
	FixedWrapper,
	FlexItem,
	HBox,
	Text
} from './common';
import CoupangAds from './CoupangAds';
import ShareButton from './ShareButton';

const FooterWrapper = styled.footer``;
export type FooterButtonOptions = {
	gaId?: string;
	to?: string;
	text?: string;
	loading?: boolean;
	animation?: boolean;
	disabled?: boolean;
	share?: {
		data: ShareData;
	};
};

const Footer: React.FC<{
	buttonOptions?: FooterButtonOptions;
}> = (props) => {
	const { state, dispatch } = useStore();
	const location = useLocation();
	const wrapperRef = useRef<HTMLDivElement>(null);
	const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

	const [styles, api] = useSpring(() =>
		state.initedApp
			? {}
			: {
					from: { y: 300 }
			  }
	);

	useEffect(() => {
		// alert(window.innerHeight - (wrapperRef.current?.offsetTop ?? 0));
		const STD_FOOTER_HEIGHT = 106;
		dispatch({
			type: 'screenDiff/set',
			payload: `${
				window.outerHeight -
				(wrapperRef.current?.offsetTop ?? 0) -
				STD_FOOTER_HEIGHT
			}`
		});
	}, [wrapperRef, dispatch]);

	useEffect(() => {
		if (!state.initedApp) {
			if (!props.buttonOptions?.loading) {
				api(() => ({
					to: { y: 0 }
				}));

				dispatch({
					type: 'app/init',
					payload: ''
				});
			}
		}
	}, [state, api, props, dispatch]);

	return (
		<FixedWrapper $spacing={16} $bottom="0px" ref={wrapperRef}>
			<BlurBox>
				<HBox $gap="15px">
					{props.buttonOptions?.to && props.buttonOptions?.text && (
						<FlexItem $flex="1">
							<animated.div style={{ ...styles }}>
								<Link to={props.buttonOptions.to}>
									<Button
										id={props.buttonOptions.gaId}
										$py={12}
										type="button"
										disabled={props.buttonOptions?.disabled}
									>
										{props.buttonOptions.text}
									</Button>
								</Link>
							</animated.div>
						</FlexItem>
					)}
					{
						// !!window.navigator.share &&
						isMobile && props.buttonOptions?.share?.data && (
							<FlexItem $flex="1">
								<ShareButton shareData={props.buttonOptions?.share?.data} />
							</FlexItem>
						)
					}
				</HBox>
				<FooterWrapper>
					<Box $my={12}>
						<Text $variant="span" $textAlign="center" $bold={true}>
							이메일 주소 💌 evcaro.cs@gmail.com
						</Text>
					</Box>
				</FooterWrapper>
			</BlurBox>
			{location.pathname !== '/' ? <CoupangAds></CoupangAds> : ''}
		</FixedWrapper>
	);
};

export default Footer;
