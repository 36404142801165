import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Theme } from './styles/theme';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<ThemeProvider theme={Theme}>
		{/* ! React.StrictMode 사용시 컴포넌트 두번 마운트 되는 이슈가 있어 주석 */}
		{/* <React.StrictMode> */}
		<App />
		{/* </React.StrictMode> */}
	</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
