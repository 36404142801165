import React from 'react';
import { Button } from './common';

/**
 * @TODO
 * 공유 후 로직 생각
 */
const ShareButton: React.FC<{
	shareData: ShareData;
}> = (props) => {
	const handleClickShare = async () => {
		if (!window.navigator.share) {
			alert('브라우저에서 지원하지 않는 기능입니다.');
			return;
		}

		props.shareData.url = `${props.shareData.url}`;
		window.navigator.share(props.shareData);
	};

	return (
		<Button
			id="btn-result-share"
			type="button"
			$py={12}
			onClick={handleClickShare}
			$bgColor="primary01"
			$border="primary01"
		>
			공유하기
		</Button>
	);
};

export default ShareButton;
