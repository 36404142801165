import React from 'react';
import Layout from '../components/Layout';

const LoadingAds: React.FC<{}> = (_) => {
	return (
		<Layout transition>
			<h3>로딩 광고페이지 입니다.</h3>
		</Layout>
	);
};

export default LoadingAds;
