import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function usePath(): {
	isRoot: boolean;
} {
	const location = useLocation();
	const isRoot = useMemo(() => {
		const l = window.location;
		return ['/'].includes(l.href.replace(l.origin, ''));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return {
		isRoot
	};
}
