import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BackButton from './BackButton';
import { Box, HBox, TransparentButton } from './common';
import usePath from '../hooks/usePath';
import Logo from './Logo';

const AppBarWrapper = styled.div`
	position: fixed;
	width: 100vw;
	z-index: 100;

	background-color: white;
`;

const AppBarInnerWrapper = styled.div`
	height: 50px;
	max-width: 460px;
	width: 100vw;
	margin: auto;

	border: 1px solid ${(props) => props.theme.Colors.lightFont};
`;

const AppBar: React.FC<{}> = () => {
	const navigate = useNavigate();

	const goRoot = () => {
		navigate('/');
	};

	return (
		<AppBarWrapper>
			<AppBarInnerWrapper>
				<HBox $justifyContent="space-between" $alignItems="start">
					<Box $px={12} $width="26px" $height="26px">
						<BackButton />
					</Box>
					<Box $p={8}>
						<TransparentButton onClick={goRoot}>
							<Logo type={2} />
						</TransparentButton>
					</Box>
					<Box $px={12} $width="26px" $height="26px">
						{/* <BackButton /> */}
					</Box>
				</HBox>
			</AppBarInnerWrapper>
		</AppBarWrapper>
	);
};

/**
 * root(/)일때 렌더링되지 않는다.
 */
const Header: React.FC<{}> = (_) => {
	const { isRoot } = usePath();

	if (isRoot) return null;

	return (
		<div>
			<AppBar />
		</div>
	);
};

export default Header;
