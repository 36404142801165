import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { DisplayManufacturer } from '../../interfaces/DisplayManufacturer';
import { toArray } from '../../utils';

export function useDisplayManufacturers(): UseQueryResult<
	DisplayManufacturer[],
	unknown
> {
	return useQuery({
		queryKey: ['display_manufacturers'],
		queryFn: async () =>
			await fetchData<Record<string, DisplayManufacturer> | null>(
				'/display_manufacturers'
			)
				.then((values) => values ?? {})
				.then((values) => toArray<DisplayManufacturer>(values))
	});
}
