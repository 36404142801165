import React from 'react';
import styled from 'styled-components';

import hyundai from '../assets/manufacture-logos/현대.png';
import genesis from '../assets/manufacture-logos/제네시스.png';
import kia from '../assets/manufacture-logos/기아.svg';
import tesla from '../assets/manufacture-logos/테슬라.svg';
import bmw from '../assets/manufacture-logos/BMW.png';
import benz from '../assets/manufacture-logos/벤츠.svg';
import audi from '../assets/manufacture-logos/아우디.svg';
import volkswagen from '../assets/manufacture-logos/폭스바겐.svg';
import chevrolet from '../assets/manufacture-logos/쉐보레.svg';
import volvo from '../assets/manufacture-logos/볼보.svg';
import polestar from '../assets/manufacture-logos/폴스타.png';
import ssangyong from '../assets/manufacture-logos/KG_Mobility.png';
import peugeot from '../assets/manufacture-logos/푸조.svg';
import citroen from '../assets/manufacture-logos/시트로엥.svg';
import smart from '../assets/manufacture-logos/스마트솔루션즈.png';
import daechangMotors from '../assets/manufacture-logos/대창모터스.png';
import maiv from '../assets/manufacture-logos/마이브.svg';
import cevo from '../assets/manufacture-logos/쎄보모빌리티.png';

type ManufactureName =
	| '현대자동차'
	| '기아'
	| '제네시스'
	| '테슬라'
	| 'BMW'
	| '벤츠'
	| '아우디'
	| '폭스바겐'
	| '쉐보레'
	| '볼보'
	| '폴스타'
	| 'KG모빌리티(쌍용)'
	| '푸조'
	| '시트로엥'
	| '대창모터스'
	| '스마트'
	| '쎄보모빌리티'
	| '마이브';

interface logoProps {
	type: string;
}

const LogoImg = styled.img`
	display: inline-flex;
	vertical-align: middle;
	margin-right: 4px;
	height: 18px;
`;
/* manufacturer_id
현대자동차: 1
기아: 2
제네시스: 1
테슬라: 5
BMW: 3
벤츠: 6
아우디: 8
폭스바겐: 8
쉐보레: 4
볼보: 12
폴스타: 11
쌍용자동차: 10
푸조: 7
시트로엥: 7
대창모터스: 13
스마트: 9
쎄보모빌리티: 15
마이브: 14
*/

const ManufactureLogo: React.FC<logoProps> = (props) => {
	const typeName = props.type as ManufactureName;
	switch (typeName) {
		case '현대자동차':
			return <LogoImg src={hyundai} alt="현대 로고" />;
		case '기아':
			return <LogoImg src={kia} alt="기아 로고" style={{ maxWidth: '30px' }} />;
		case '제네시스':
			return (
				<LogoImg src={genesis} alt="제네시스 로고" style={{ height: '10px' }} />
			);
		case '테슬라':
			return <LogoImg src={tesla} alt="테슬라 로고" />;
		case 'BMW':
			return <LogoImg src={bmw} alt="BMW 로고" />;
		case '벤츠':
			return <LogoImg src={benz} alt="벤츠 로고" />;
		case '아우디':
			return <LogoImg src={audi} alt="아우디 로고" />;
		case '폭스바겐':
			return <LogoImg src={volkswagen} alt="폭스바겐 로고" />;
		case '쉐보레':
			return <LogoImg src={chevrolet} alt="쉐보레 로고" />;
		case '볼보':
			return <LogoImg src={volvo} alt="볼보 로고" />;
		case '폴스타':
			return <LogoImg src={polestar} alt="폴스타 로고" />;
		case 'KG모빌리티(쌍용)':
			return <LogoImg src={ssangyong} alt="KG모빌리티 로고" />;
		case '푸조':
			return <LogoImg src={peugeot} alt="푸조 로고" />;
		case '시트로엥':
			return <LogoImg src={citroen} alt="시트로엥 로고" />;
		case '대창모터스':
			return <LogoImg src={daechangMotors} alt="대창모터스 로고" />;
		case '스마트':
			return (
				<LogoImg
					src={smart}
					alt="스마트솔루션즈 로고"
					style={{ height: '10px' }}
				/>
			);
		case '쎄보모빌리티':
			return <LogoImg src={cevo} alt="쎄보모빌리티 로고" />;
		case '마이브':
			return <LogoImg src={maiv} alt="마이브 로고" />;
		default:
			return <span />;
	}
};

export default ManufactureLogo;
