import styled from 'styled-components';
import { ColorsType, TextSizesType } from '../styles/theme';
import { CSSTypes } from '../types/cssAttrs';

type StyleSetKey = 'p' | 'm';

const getStyleName = (key: StyleSetKey) => {
	if (key === 'm') {
		return 'margin';
	} else if (key === 'p') {
		return 'padding';
	}

	return '';
};

type StyleSet<K extends StyleSetKey> = {
	[_k in `$${K}`]?: number;
} & {
	[_k in `$${K}x`]?: number;
} & {
	[_k in `$${K}y`]?: number;
} & {
	[_k in `$${K}t`]?: number;
} & {
	[_k in `$${K}l`]?: number;
} & {
	[_k in `$${K}r`]?: number;
} & {
	[_k in `$${K}b`]?: number;
};

/**
 * @see getStyleBySet
 * 	default, x, y, t, l, r, b 의 값을 받아 적절한 style 문자열을 반환한다.
 */
const getStyleBySet = <K extends StyleSetKey>(
	styleSet: StyleSet<K>,
	key: K,
	unit: CSSTypes.SizeUnit
) => {
	const styleName = getStyleName(key);

	let r = '';

	if (styleSet[`$${key}`]) {
		r += `${styleName}: ${styleSet[`$${key}`]}${unit};`;
	}

	if (styleSet[`$${key}x`]) {
		r += `
			${styleName}-left: ${styleSet[`$${key}x`]}${unit};
			${styleName}-right: ${styleSet[`$${key}x`]}${unit};
		`;
	} else {
		if (styleSet[`$${key}l`])
			r += `${styleName}-left: ${styleSet[`$${key}l`]}${unit};`;
		if (styleSet[`$${key}r`])
			r += `${styleName}-right: ${styleSet[`$${key}r`]}${unit};`;
	}

	if (styleSet[`$${key}y`]) {
		r += `
			${styleName}-top: ${styleSet[`$${key}y`]}${unit};
			${styleName}-bottom: ${styleSet[`$${key}y`]}${unit};
		`;
	} else {
		if (styleSet[`$${key}t`])
			r += `${styleName}-top: ${styleSet[`$${key}t`]}${unit};`;
		if (styleSet[`$${key}b`])
			r += `${styleName}-bottom: ${styleSet[`$${key}b`]}${unit};`;
	}

	return r;
};

export type PaddingSet = StyleSet<'p'>;
const getPaddingStyle = (set: PaddingSet, unit: CSSTypes.SizeUnit) => {
	return getStyleBySet<'p'>(set, 'p', unit);
};

export type MarginSet = StyleSet<'m'>;
const getMarginStyle = (set: MarginSet, unit: CSSTypes.SizeUnit) => {
	return getStyleBySet<'m'>(set, 'm', unit);
};

type SizeSet = {
	$width?: CSSTypes.WidthSize;
	$height?: CSSTypes.HeightSize;
};

const getSizeStyle = (set: SizeSet) => {
	let r = '';

	if (set.$width) {
		r += `width: ${set.$width};`;
	}

	if (set.$height) {
		r += `height: ${set.$height};`;
	}

	return r;
};

type BoxProps = {
	$textAlign?: CSSTypes.TextAlign;
	$lineHeight?: CSSTypes.LineHeight;
	$float?: CSSTypes.Float;
} & MarginSet &
	SizeSet &
	MarginSet &
	PaddingSet;

export const Box = styled.div<BoxProps>`
	${(props) => getMarginStyle(props, 'px')}
	${(props) => getPaddingStyle(props, 'px')}
	line-height: ${(props) => props.$lineHeight ?? '1'};
	text-align: ${(props) => props.$textAlign ?? 'center'};

	${(props) => getSizeStyle(props)}
`;

type FlexBoxProps = {
	$justifyContent?: CSSTypes.JustifyContent;
	$alignItems?: CSSTypes.AlignItems;
	$gap?: CSSTypes.Gap;
	$bgColor?: keyof ColorsType;
} & SizeSet &
	MarginSet &
	PaddingSet;

export const FlexBox = styled.div<FlexBoxProps>`
	display: flex;
	justify-content: ${(props) => props.$justifyContent ?? 'start'};
	align-items: ${(props) => props.$alignItems ?? 'baseline'};
	gap: ${(props) => props.$gap ?? '0'};

	${(props) => getSizeStyle(props)}
	${(props) => getMarginStyle(props, 'px')}
	${(props) => getPaddingStyle(props, 'px')}

	background-color: ${(props) =>
		props.$bgColor ? props.theme.Colors[props.$bgColor] : 'transparent'};
`;

export const VBox = styled(FlexBox)`
	flex-direction: column;
`;

export const HBox = styled(FlexBox)`
	flex-direction: row;
	width: 100%;
`;

type FlexItemSet = {
	$flex?: CSSTypes.Flex;
	$grow?: CSSTypes.FlexGrow;
	$shirink?: CSSTypes.FlexShrink;
	$basis?: CSSTypes.FlexBasis;
};

const getFlexItemStyle = (set: FlexItemSet) => {
	if (set.$flex) {
		return `flex: ${set.$flex}`;
	}

	let r = '';

	if (set.$grow) {
		r += `flex-grow: ${set.$grow};`;
	}

	if (set.$shirink) {
		r += `flex-shirink: ${set.$shirink};`;
	}

	if (set.$basis) {
		r += `flex-basis: ${set.$basis};`;
	}

	return r;
};

type FlexItemProps = {
	$align?: CSSTypes.AlignSelf;
} & FlexItemSet &
	SizeSet &
	MarginSet &
	PaddingSet;

export const FlexItem = styled.div<FlexItemProps>`
	align-self: ${(props) => props.$align ?? 'normal'};
	${(props) => getFlexItemStyle(props)}

	${(props) => getSizeStyle(props)}
	${(props) => getMarginStyle(props, 'px')}
	${(props) => getPaddingStyle(props, 'px')}
`;

type FloatingWrapperProps = {
	$spacing?: number;

	$top?: CSSTypes.Size;
	$bottom?: CSSTypes.Size;
	$left?: CSSTypes.Size;
	$right?: CSSTypes.Size;
} & PaddingSet;

const parseNumber = (str: string) => (isNaN(parseInt(str)) ? 0 : parseInt(str));
const dobuleNumber = (num?: number) => (num ? num * 2 : 0);
const dobuleNumberWithUnit = (
	sizeVal: CSSTypes.Size,
	unit: CSSTypes.SizeUnit
) => parseNumber(sizeVal.replace(new RegExp(unit, 'g'), '')) * 2 + unit;
const dobuleSizeNumber = (sizeVal?: CSSTypes.Size) => {
	if (sizeVal?.includes('px')) {
		return dobuleNumberWithUnit(sizeVal, 'px');
	} else if (sizeVal?.includes('em')) {
		return dobuleNumberWithUnit(sizeVal, 'em');
	} else if (sizeVal?.includes('rem')) {
		return dobuleNumberWithUnit(sizeVal, 'rem');
	} else if (sizeVal?.includes('%')) {
		return dobuleNumberWithUnit(sizeVal, '%');
	} else {
		return sizeVal;
	}
};

export const FixedWrapper = styled.div<FloatingWrapperProps>`
	position: fixed;

	width: calc(100% - ${(props) => dobuleNumber(props.$spacing)}px);
	max-width: calc(
		${(props) => props.theme.Common.appMaxWidth} -
			${(props) => dobuleSizeNumber(props.theme.Common.appPadding)}
	);

	${(props) => (props.$top ? `top: ${props.$top ?? 0}` : '')};
	${(props) => (props.$left ? `left: ${props.$left ?? 0}` : '')};
	${(props) => (props.$right ? `right: ${props.$right ?? 0}` : '')};
	${(props) => (props.$bottom ? `bottom: ${props.$bottom ?? 0}` : '')};

	${(props) => getPaddingStyle(props, 'px')}

	${(props) =>
		props.$spacing
			? `
		padding-left: ${props.$spacing}px;
		padding-right: ${props.$spacing}px;
	`
			: ''}
`;

type CardWrapperProps = {
	$width?: CSSTypes.WidthSize;
	$height?: CSSTypes.HeightSize;

	$radius?: number;
} & MarginSet &
	PaddingSet;

export const CardWrapper = styled(HBox)<CardWrapperProps>`
	justify-content: ${(props) => props.$justifyContent ?? 'center'};
	align-items: center;

	${(props) => getMarginStyle(props, 'px')}
	${(props) => getPaddingStyle(props, 'px')}

	width: ${(props) => props.$width ?? '100%'};
	height: ${(props) => props.$height ?? '100%'};
	border-radius: ${(props) => props.$radius ?? 0}px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px,
		rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
`;

type LabelProps = {} & MarginSet;
export const Label = styled.label<LabelProps>`
	${(props) => getMarginStyle(props, 'px')}
	font-size: ${(props) => props.theme.TextSizes.span};
	font-weight: bold;
`;

type HeadLineProps = {
	$variant?: keyof TextSizesType;
	$whiteSpace?: CSSTypes.WhiteSpace;
	$nobold?: boolean;
	$color?: keyof ColorsType;
	$lineHeight?: CSSTypes.LineHeight;
	$textAligh?: CSSTypes.TextAlign;
} & MarginSet;

export const HeadLine = styled.h1<HeadLineProps>`
	white-space: ${(props) => props.$whiteSpace ?? 'wrap'};
	text-align: ${(props) => props.$textAligh ?? 'left'};
	font-size: ${(props) => props.theme.TextSizes[props.$variant ?? 'h1']};
	font-weight: ${(props) => (props.$nobold ? 400 : 700)};
	color: ${(props) =>
		props.$color ? props.theme.Colors[props.$color] : props.theme.Colors.dark};
	line-height: ${(props) => props.$lineHeight ?? '1'};
	margin: 0;
	${(props) => getMarginStyle(props, 'px')}
`;

type SubHeadLineProps = {
	$variant?: keyof TextSizesType;
	$nobold?: boolean;
	$color?: keyof ColorsType;
	$lineHeight?: CSSTypes.LineHeight;
} & MarginSet;

export const SubHeadLine = styled.h4<SubHeadLineProps>`
	word-break: keep-all;
	text-align: left;
	font-size: ${(props) => props.theme.TextSizes[props.$variant ?? 'h4']};
	font-weight: ${(props) => (props.$nobold ? 400 : 500)};
	color: ${(props) =>
		props.$color ? props.theme.Colors[props.$color] : props.theme.Colors.font};
	line-height: ${(props) => props.$lineHeight ?? '1.2'};

	margin: 0;
	padding: 0;

	margin: 0;
	${(props) => getMarginStyle(props, 'px')}
`;

type TextProps = {
	$variant?: keyof TextSizesType;
	$bold?: boolean;
	$color?: keyof ColorsType;
	$textAlign?: CSSTypes.TextAlign;
	$lineHeight?: CSSTypes.LineHeight;
	$wordBreak?: string;
};

export const Text = styled.span<TextProps>`
	font-size: ${(props) => props.theme.TextSizes[props.$variant ?? 'p1']};
	font-weight: ${(props) => (props.$bold ? 700 : 400)};
	text-align: ${(props) => props.$textAlign ?? 'center'};
	color: ${(props) =>
		props.$color ? props.theme.Colors[props.$color] : props.theme.Colors.dark};
	line-height: ${(props) => props.$lineHeight ?? '1'};
	word-break: ${(props) => props.$wordBreak ?? 'keep-all'};
`;

type ButtonProps = {
	width?: CSSTypes.WidthSize;
	$border?: keyof ColorsType;
	$bgColor?: keyof ColorsType;
} & MarginSet &
	PaddingSet;

export const Button = styled.button<ButtonProps>`
	width: 100%;
	font-size: 18px;
	text-align: center;
	border-radius: 35px;
	line-height: 1.5;
	font-weight: 700;
	color: white;
	border: 1px solid
		${(props) =>
			props.$border
				? props.theme.Colors[props.$border]
				: props.theme.Colors.dark};
	width: ${(props) => props.width ?? 300};
	background: ${(props) =>
		props.$bgColor
			? props.theme.Colors[props.$bgColor]
			: props.theme.Colors.dark};

	${(props) => getMarginStyle(props, 'px')}
	${(props) => getPaddingStyle(props, 'px')}
	:disabled {
		border: 1px solid ${(props) => props.theme.Colors.lightFont};
		background: ${(props) => props.theme.Colors.lightFont};
	}
`;

export const TransparentButton = styled(Button)`
	background: ${(props) => props.theme.Colors.transparent};
	border: 1px solid ${(props) => props.theme.Colors.transparent};
`;

export const BlurBox = styled.div`
	::before {
		content: '';

		position: absolute;

		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background: inherit;

		z-index: -1;

		backdrop-filter: blur(1px);
	}
`;
