import React, { useEffect, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import {
	Box,
	FlexItem,
	HBox,
	HeadLine,
	SubHeadLine,
	Text,
	VBox
} from '../components/common';
import ManufactureLogo from '../components/ManufactureLogos';
import ErrorBoundary from '../components/ErrorBoundary';
import Layout from '../components/Layout';
import LoadingContent from '../components/LoadingContent';
import { ColorsType } from '../styles/theme';
import { currencyNumberFormatter } from '../utils/formatter';
import { useDisplayCarModel } from '../hooks/queries/useDisplayCarModel';
import { useSubsidy } from '../hooks/queries/useSubsidy';
import { useRecentInfoData } from '../hooks/queries/useRecentInfoData';
import { useSigungu } from '../hooks/queries/useSigungu';
import { useRegion } from '../hooks/queries/useRegion';
import { useDisplayManufacturer } from '../hooks/queries/useDisplayManufacturer';
import { useContact } from '../hooks/queries/useContact';
import { useYesterdayInfoData } from '../hooks/queries/useYesterdayInfoData';

import { Region } from '../interfaces/Region';
import { Sigungu } from '../interfaces/Sigungu';
import { Contact } from '../interfaces/Contact';
import { DisplayCarModel } from '../interfaces/DisplayCarModel';
import { Subsidy } from '../interfaces/Subsidy';
import { InfoData } from '../interfaces/InfoData';
import { DisplayManufacturer } from '../interfaces/DisplayManufacturer';

import { each } from '../utils';
import { useLocation } from 'react-router-dom';
import IconQuestion from '../assets/icons/question-mark-circle.svg';

type BadgeItem = {
	icon: React.ReactNode;
	color: keyof ColorsType;
};

const Badge = styled(HBox)`
	width: 32px;
	height: 32px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
`;

const EventBadge = styled(HBox)`
	margin-right: 4px;
	width: 80px;
	height: 24px;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 11px;
	color: #ffffff;
	background-color: #322cff;
`;

const NoticeBox = styled(HBox)`
	width: calc(100% - 16px);
	height: 28px;
	border-radius: 16px;
	justify-content: center;
	align-items: center;
	word-break: keep-all;
`;

const TooltipHoverStyle = styled(Text)`
	cursor: default;
`;

const Tooltip = styled.img`
	width: 12px;
	margin-left: 4px;
`;

const TooltipContent = styled(HBox)`
	padding: 2px;
	line-height: 1.2;
	text-align: center;
`;

const Highlight = styled(HBox)`
	z-index: -1;
	display: inline;
	box-shadow: inset 0 -8px 0 #d9fcdb;
	transition: 0.2s all;
`;

const Icon: React.FC<{
	item: BadgeItem;
}> = (props) => (
	<VBox>
		<Badge $bgColor={props.item.color} $mr={8}>
			{props.item.icon}
		</Badge>
	</VBox>
);

const ResultRow: React.FC<
	React.PropsWithChildren<{
		iconItem: BadgeItem;
		label: string;
		AddedRows?: React.ReactNode;
	}>
> = (props) => (
	<Box $mb={20} $width="100%">
		<HBox $justifyContent="space-between" $alignItems="center">
			<HBox>
				<Icon item={props.iconItem} />
				<SubHeadLine
					$variant="h6"
					$color="dark"
					$nobold={true}
					style={{ fontWeight: '700' }}
				>
					{props.label}
				</SubHeadLine>
			</HBox>
			<HBox $alignItems="center" style={{ textAlign: 'right' }}>
				<FlexItem $flex="1">{props.children}</FlexItem>
			</HBox>
		</HBox>
		{props.AddedRows}
	</Box>
);

const TooltipText: React.FC<
	React.PropsWithChildren<{
		id: string;
		text?: string | React.ReactNode;
		options?: {
			color?: keyof ColorsType;
			tooltipText?: string;
			discount?: number;
			label?: string;
			unit?: string;
		};
	}>
> = (props) => {
	return (
		<>
			<Text $textAlign="right" data-tip data-for={props.id}>
				<>
					<HBox $justifyContent="flex-end" $alignItems="center">
						{props.options?.label && (
							<EventBadge>{props.options?.label}</EventBadge>
						)}
						<TooltipHoverStyle
							$variant="h5"
							$lineHeight="32px"
							$bold={true}
							$color={props.options?.color}
						>
							{props.text}
						</TooltipHoverStyle>
						{props.options?.unit ? ' ' : ''}
						{props.options?.unit && (
							<Text $variant="p2">{props.options.unit}</Text>
						)}
						<Tooltip src={IconQuestion} alt="tooltip question mark" />
					</HBox>
					{props.options?.discount && (
						<HBox $justifyContent="flex-end" $mt={4}>
							<Text $variant="p3" $color="red01" style={{ fontWeight: 500 }}>
								{Math.floor(props.options?.discount / 10000)}만원 할인이
								적용되었어요!
							</Text>
						</HBox>
					)}
				</>
			</Text>

			{props.options?.tooltipText && (
				<ReactTooltip
					id={props.id}
					aria-haspopup="true"
					type="dark"
					place="bottom"
				>
					<TooltipContent
						dangerouslySetInnerHTML={{ __html: props.options.tooltipText }}
					/>
				</ReactTooltip>
			)}
		</>
	);
};

type ResultPageContentProp = {
	region: Region;
	sigungu: Sigungu;
	manufacturer: DisplayManufacturer;
	contact: Contact;
	carModel: DisplayCarModel;
	subsidy?: Subsidy | null;
	infoData?: InfoData;
	yInfoData: InfoData | null;
};

const ResultPageContent: React.FC<ResultPageContentProp> = (props) => {
	const {
		region,
		sigungu,
		manufacturer,
		contact,
		carModel,
		subsidy,
		infoData,
		yInfoData
	} = props;

	const savedTime = useMemo(() => {
		if (!infoData?.date) return new Date();
		return new Date(infoData.date);
	}, [infoData]);
	const remainedCount = useMemo(
		() => (infoData?.announced_last ?? 0) - (infoData?.relased_last ?? 0),
		[infoData?.relased_last, infoData?.announced_last]
	);
	const isPossible = useMemo(
		() => subsidy && remainedCount > 0,
		[remainedCount, subsidy]
	);
	const diff = useMemo(() => {
		const yesterdayRemainedCount =
			(yInfoData?.remained_last ?? 0) - (infoData?.remained_last ?? 0);
		return yesterdayRemainedCount;
	}, [yInfoData?.remained_last, infoData?.remained_last]);
	const price = useMemo<number>(() => {
		return carModel?.price || 0;
	}, [carModel]);

	// formatting
	const regionFilter = (region: string, sigungu: string): string => {
		if (sigungu.includes(region)) {
			return sigungu;
		} else {
			return region + ' ' + sigungu;
		}
	};

	return (
		<Layout
			transition
			footerButtonOptions={{
				gaId: 'btn-result-restart',
				to: '/car-model',
				text: '다시하기',
				share: {
					data: {
						title: '2023년 내 전기차 보조금은 얼마일까?',
						text: '',
						url: `${location.pathname}${location.search}`
					}
				}
			}}
		>
			<VBox $py={16} $px={25}>
				<Box $mt={12} $textAlign="left" $lineHeight="1.3">
					<Text $variant="p4">
						{Intl.DateTimeFormat('ko-kr', {
							dateStyle: 'long',
							timeStyle: 'long'
						})
							.format(savedTime)
							.slice(6, 23)}{' '}
						기준 (무공해차 통합누리집)
					</Text>
					<HeadLine $variant="h6" $mt={4} $mb={8}>
						<Text
							$variant="h6"
							$lineHeight="1.2"
							$textAlign="left"
							$bold={true}
						>
							2023년{' '}
							{regionFilter(
								region?.region_name ?? '',
								sigungu?.sigungu_name ?? ''
							)}
						</Text>
						<br />
						<Text
							$variant="h6"
							$textAlign="left"
							$bold={true}
							$lineHeight="1.3"
						>
							<ManufactureLogo
								type={manufacturer?.display_manufacturer_name ?? ''}
							/>
							<Highlight> {carModel?.display_model_name}</Highlight>
						</Text>
					</HeadLine>
				</Box>
				<VBox $mt={16} $width="100%">
					<ResultRow
						label="신청 가능 여부"
						iconItem={{
							icon: isPossible ? '🙆🏻‍♀️' : '🙅🏻‍♀️',
							color: 'secondary06'
						}}
					>
						<TooltipText
							id="isPossible"
							text={isPossible ? '가능' : '불가능'}
							options={{
								color: isPossible ? 'dark' : 'red01',
								tooltipText: isPossible
									? `
									접수대수가 공고대수보다 적으면
									<br /> 보조금 신청이 가능합니다.
								`
									: `
									접수대수가 공고대수보다 많으면
									<br />
									보조금 신청이 불가합니다.
								`
							}}
						/>
					</ResultRow>

					<ResultRow
						label="잔여 대수"
						iconItem={{
							icon: '🙌🏻',
							color: 'secondary07'
						}}
						AddedRows={
							yInfoData && remainedCount !== 0 && remainedCount > 0 ? (
								<HBox $justifyContent="flex-end" $mt={4}>
									<Text
										$variant="p3"
										$color="blue01"
										style={{ fontWeight: 500 }}
									>
										어제보다 {Math.abs(diff)}대 줄었어요!
									</Text>
								</HBox>
							) : (
								''
							)
						}
					>
						<TooltipText
							id="remainedCount"
							text={currencyNumberFormatter.format(
								remainedCount > 0 ? remainedCount : 0
							)}
							options={{
								unit: '대',
								tooltipText: `보조금 수령 가능한 잔여 대수입니다. <br />
								${Intl.DateTimeFormat('ko', {
									dateStyle: 'short',
									timeStyle: 'short'
								}).format(savedTime)} 
										업데이트`
							}}
						/>
					</ResultRow>

					<ResultRow
						label="예상 보조금"
						iconItem={{
							icon: '💰',
							color: 'primary02'
						}}
					>
						<TooltipText
							id="subsidy"
							text={
								subsidy
									? currencyNumberFormatter.format(subsidy.subsidy)
									: '없음'
							}
							options={{
								unit: subsidy ? '만원' : '',
								tooltipText: `
									${sigungu?.sigungu_name}
									모델별 보조금으로
									<br />
									국비 ${currencyNumberFormatter.format(
										subsidy?.goverment_subsidy ?? 0
									)}만원 + 지방비 
									${currencyNumberFormatter.format(subsidy?.region_subsidy ?? 0)}만원 합계입니다.
								`
							}}
						/>
					</ResultRow>

					<ResultRow
						label="예상 차 가격"
						iconItem={{
							icon: '🚙',
							color: 'lightFont'
						}}
					>
						<TooltipText
							id="price"
							text={
								typeof carModel.price === 'string'
									? ''
									: `${currencyNumberFormatter.format(
											Math.floor(price / 10000) - (subsidy?.subsidy ?? 0)
									  )}`
							}
							options={{
								unit:
									typeof carModel.price === 'string' ? carModel.price : '만원',
								discount: carModel.discount,
								label: carModel.label,
								tooltipText: `
									보조금, 개소세, 전기차 감면이 적용된 차 가격이며
									<br />
									기타 세금은 포함되어있지 않습니다.
									<br />
									(취득세, 공채, 부대비용, 기타비용, 탁송료 등)
								`
							}}
						/>
					</ResultRow>
					<NoticeBox $bgColor="lightFont" $mt={12} $mb={20} $py={4} $px={4}>
						<Text $variant="p4" $lineHeight={1.2}>
							💡 더 정확한 보조금은 {contact?.department} (
							<a href={`tel:${contact?.contact}`}>{contact?.contact}</a>) 에
							문의해보세요.
						</Text>
					</NoticeBox>
				</VBox>
			</VBox>
		</Layout>
	);
};

const ResultPage: React.FC<{}> = (_) => {
	const location = useLocation();

	const searchParams = useMemo(() => {
		const urlSearchParams = new URLSearchParams(location.search);

		const params = {
			manufacturerId: Number(urlSearchParams.get('manufacturer_id')),
			modelId: Number(urlSearchParams.get('model_id')),
			regionId: Number(urlSearchParams.get('region_id')),
			sigunguId: Number(urlSearchParams.get('sigungu_id'))
		};

		each<number>(params, (n) => {
			if (isNaN(n)) throw Error('invalid params');
		});

		return params;
	}, [location]);

	const { data: region } = useRegion(searchParams.regionId);
	const { data: sigungu } = useSigungu(searchParams.sigunguId);
	const { data: manufacturer } = useDisplayManufacturer(
		searchParams.manufacturerId
	);
	const { data: contact } = useContact(searchParams.sigunguId);
	const { data: carModel } = useDisplayCarModel(searchParams.modelId);
	const { data: subsidy } = useSubsidy(
		searchParams.sigunguId,
		searchParams.modelId
	);
	const { data: infoData } = useRecentInfoData(searchParams.sigunguId);
	const { data: yInfoData } = useYesterdayInfoData(searchParams.sigunguId);

	const data = useMemo<ResultPageContentProp | null>(() => {
		if (region && sigungu && manufacturer && contact && carModel && infoData) {
			const result: Partial<ResultPageContentProp> = {};

			result.region = region;
			result.sigungu = sigungu;
			result.manufacturer = manufacturer;
			result.contact = contact;
			result.carModel = carModel;
			result.subsidy = subsidy;
			result.infoData = infoData;
			result.yInfoData = yInfoData;
			return result as ResultPageContentProp;
		}

		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		region,
		sigungu,
		manufacturer,
		contact,
		carModel,
		subsidy,
		infoData,
		yInfoData
	]);

	useEffect(() => {
		if (
			searchParams.manufacturerId &&
			searchParams.modelId &&
			searchParams.regionId &&
			searchParams.sigunguId
		) {
			localStorage.setItem('recentSearchParams', JSON.stringify(searchParams));
		} else {
			// window.history.back();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ErrorBoundary>
			{!data && <LoadingContent />}
			{data && <ResultPageContent {...data} />}
		</ErrorBoundary>
	);
};

export default ResultPage;
