import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { DisplayManufacturer } from '../../interfaces/DisplayManufacturer';
import { equalTo, orderByChild, query } from 'firebase/database';
import { toArray } from '../../utils';

export function useDisplayManufacturer(
	manufacturer_id: number
): UseQueryResult<DisplayManufacturer, unknown> {
	return useQuery({
		queryKey: ['display_manufacturer', manufacturer_id],
		queryFn: async () =>
			await fetchData<Record<string, DisplayManufacturer> | null>(
				'/display_manufacturers',
				(ref) => {
					return query(
						ref,
						orderByChild('display_manufacturer_id'),
						equalTo(manufacturer_id)
					);
				}
			)
				.then((values) => values ?? {})
				.then((values) => toArray<DisplayManufacturer>(values))
				.then((values) => (values ? values[0] : null))
	});
}
