import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import usePath from '../hooks/usePath';
import useStore from '../hooks/useStore';
import { Box } from './common';
import Footer, { FooterButtonOptions } from './Footer';

const Container = styled.div<{
	transition: 'to-left' | 'to-right' | '';
}>`
	width: 100vw;
	min-height: 100vh;

	${(props) =>
		props.transition === 'to-left'
			? `
  animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
  animation-name: pageslide-to-left;
	position: fixed;
	overflow: hidden;
  `
			: props.transition === 'to-right'
			? `
  animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
  animation-name: pageslide-to-right;
	position: fixed;
	overflow: hidden;
  `
			: ''}

	@keyframes pageslide-to-left {
		0% {
			left: 100vw;
			opacity: 0;
		}
		99% {
			left: 0;
			opacity: 1;
		}
	}

	@keyframes pageslide-to-right {
		0% {
			left: -100vw;
			opacity: 0;
		}
		99% {
			left: 0;
			opacity: 1;
		}
	}
`;

const Wrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	max-width: 460px;
	min-height: 100vh;
	border: 1px solid ${(props) => props.theme.Colors.lightFont};
`;

const AppWrapper = styled.div`
	text-align: center;
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: scroll;
`;

const FooterWrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	max-width: 460px;
	border: 1px solid ${(props) => props.theme.Colors.lightFont};
`;

const Section = styled.section`
	flex: 1;
`;

const Layout: React.FC<
	React.PropsWithChildren<{
		transition?: boolean;
		footerButtonOptions?: FooterButtonOptions;
	}>
> = (props) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const { state } = useStore();
	const [isBack, _setIsBack] = useState(false);

	const footerButtonOptions = useMemo(() => {
		if (props.footerButtonOptions?.share) {
			// props.footerButtonOptions.share.imageTarget = wrapperRef.current;
		}

		return props.footerButtonOptions;
	}, [props]);

	const { isRoot } = usePath();

	return (
		<>
			<Container
				transition={props.transition ? (isBack ? 'to-right' : 'to-left') : ''}
			>
				<Wrapper ref={wrapperRef}>
					<AppWrapper>
						<Section>
							<Box $mt={isRoot ? 0 : 50} $pb={110 + state.screenDiff * 0.8}>
								{props.children}
							</Box>
						</Section>
					</AppWrapper>
				</Wrapper>
			</Container>
			<FooterWrapper>
				<Footer buttonOptions={footerButtonOptions} />
			</FooterWrapper>
		</>
	);
};

export default React.memo(Layout);
