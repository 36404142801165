import React, { useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import IconArrow from '../assets/icon-arrow02.svg';
import Memoji03 from '../assets/kbs.png';
import Memoji02 from '../assets/tsl.png';
import Memoji01 from '../assets/yrh.png';
import useStore from '../hooks/useStore';
// import AnimatedNumberText from '../components/AnimatedNumberText';
import { Box, HBox, VBox } from '../components/common';

import { HeadLine, Text } from '../components/common';
import Layout from '../components/Layout';
import Logo from '../components/Logo';
import SplashCard from '../components/SplashCard';
import { useSigungu } from '../hooks/queries/useSigungu';
import { useDisplayCarModel } from '../hooks/queries/useDisplayCarModel';

const SPEED = 250;
const LogBox = styled.a`
	display: flex;
	margin: 16px auto 0 auto;
	padding: 10px;
	width: calc(100% - 48px);
	cursor: pointer;
	align-items: center;
	word-break: keep-all;
	border-radius: 12px;
	background: ${(props) => props.theme.Colors.secondary05};
`;

const Rocket = styled.div`
	font-size: 16px;
	margin-right: 12px;
`;

const IconHBox = styled(HBox)`
	width: 72px;
	justify-content: center;
	align-items: center;
	margin-left: auto;
`;
const Icon = styled(HBox)`
	width: 24px;
`;

const Bold = styled.span`
	font-weight: 600;
`;

const Splash: React.FC<{}> = (_props) => {
	// const [num, _setNum] = React.useState(7979);
	const { state, dispatch } = useStore();
	const navigate = useNavigate();
	const [inited, setInited] = useState<boolean>(false);

	const [styles1, api1] = useSpring(() =>
		state.initedApp
			? {}
			: {
					from: { x: -200, opacity: 0 },
					config: {
						duration: SPEED,
						bounce: true
					}
			  }
	);
	const [styles2, api2] = useSpring(() =>
		state.initedApp
			? {}
			: {
					from: { x: 200, opacity: 0 },
					config: {
						duration: SPEED,
						bounce: true
					}
			  }
	);
	const [styles3, api3] = useSpring(() =>
		state.initedApp
			? {}
			: {
					from: { x: -200, opacity: 0 },
					config: {
						duration: SPEED,
						bounce: true
					}
			  }
	);

	useEffect(() => {
		if (!state.initedApp) {
			api1.start(() => ({
				to: { x: 0, opacity: 1 }
			}));

			setTimeout(() => {
				api2.start(() => ({
					to: { x: 0, opacity: 1 }
				}));

				setTimeout(() => {
					api3.start(() => ({
						to: { x: 0, opacity: 1 }
					}));

					setTimeout(() => {
						setInited(true);
					}, SPEED + 50);
				}, SPEED + 400);
			}, SPEED + 400);
		}
	}, [state, dispatch, api1, api2, api3]);

	const recentSearchParams = useMemo<URLSearchParams | null>(() => {
		try {
			const searchParams = JSON.parse(
				localStorage.getItem('recentSearchParams') as string
			);

			if (
				searchParams?.manufacturerId &&
				searchParams?.modelId &&
				searchParams?.regionId &&
				searchParams?.sigunguId
			) {
				const urlSearchParams = new URLSearchParams();
				urlSearchParams.set('manufacturer_id', searchParams.manufacturerId);
				urlSearchParams.set('model_id', searchParams.modelId);
				urlSearchParams.set('region_id', searchParams.regionId);
				urlSearchParams.set('sigungu_id', searchParams.sigunguId);
				// return `/result?${urlSearchParams.toString()}`
				return urlSearchParams;
			} else {
				throw Error();
			}
		} catch (_err) {
			// return '';
			return null;
		}
	}, []);

	const { data: sigungu } = useSigungu(
		parseInt(recentSearchParams?.get('sigungu_id') ?? '')
	);

	const { data: carModel } = useDisplayCarModel(
		parseInt(recentSearchParams?.get('model_id') ?? '')
	);

	return (
		<Layout
			footerButtonOptions={{
				gaId: 'btn-splash-start',
				to: '/car-model',
				text: '조회하기',
				animation: true,
				loading: !inited
			}}
		>
			<VBox $justifyContent="flex-start" $px={30} $py={40} $bgColor="primary01">
				<Box $mb={10}>
					<Logo type={1} />
				</Box>
				<HeadLine $variant="h4" $mb={20} $lineHeight={1.1} $color="lightFont">
					2023년 <br />내{' '}
					<Text $variant="h4" $color="secondary03" $bold={true}>
						전기차 보조금
					</Text>
					은 <br />
					얼마일까?
				</HeadLine>
				{/* <HBox>
					{'오늘  '}
					<AnimatedNumberText val={num} />
					명이 조회했어요!
				</HBox> */}
			</VBox>
			<Box>
				{recentSearchParams && sigungu && carModel ? (
					<LogBox
						id="link-splash-go"
						onClick={() => {
							navigate(`/result?${recentSearchParams.toString()}`);
						}}
					>
						<HBox $width="80%">
							<Rocket>🚀</Rocket>
							<Text
								$textAlign="left"
								$variant="p3"
								$lineHeight="1.2"
								style={{ fontWeight: 500 }}
							>
								{sigungu?.sigungu_name}, {carModel?.category} 보조금 조회
							</Text>
						</HBox>
						<IconHBox>
							<Text
								$textAlign="right"
								$variant="p3"
								$lineHeight="1.2"
								$bold={true}
								style={{ fontWeight: 600 }}
							>
								바로가기
							</Text>
							<Icon>
								<img src={IconArrow} alt="보조금 조회 바로가기" />
							</Icon>
						</IconHBox>
					</LogBox>
				) : (
					' '
				)}
				<VBox $mt={8} $pt={16} $px={25}>
					<animated.div style={{ width: '100%', ...styles1 }}>
						<SplashCard
							imageSrc={Memoji01}
							imageAlign="right"
							imageAlt="전기차 가격"
						>
							도대체 내 <Bold>전기차 가격</Bold>은 얼마인거지?
						</SplashCard>
					</animated.div>
				</VBox>
				<VBox $pt={16} $px={25}>
					<animated.div style={{ width: '100%', ...styles2 }}>
						<SplashCard
							imageSrc={Memoji02}
							imageAlign="left"
							imageAlt="전기차 잔여대수"
						>
							매일 바뀌는 <Bold>잔여대수</Bold>도 너무 궁금해
						</SplashCard>
					</animated.div>
				</VBox>

				<VBox $pt={16} $px={25}>
					<animated.div style={{ width: '100%', ...styles3 }}>
						<SplashCard
							imageSrc={Memoji03}
							imageAlign="right"
							imageAlt="전기차 보조금"
						>
							<Bold>누리집 데이터</Bold>를 간편하게 보고싶어..
						</SplashCard>
					</animated.div>
				</VBox>
			</Box>
		</Layout>
	);
};

export default Splash;
