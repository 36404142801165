import React from 'react';
import styled from 'styled-components';

import logo1 from '../assets/logo/evcaro-logo1.png';
import logo2 from '../assets/logo/evcaro-logo2.png';
import logo3 from '../assets/logo/evcaro-logo3.png';

const LogoImg = styled.img`
	height: 34px;
`;

const Logo: React.FC<{
	type: 1 | 2 | 3;
}> = (props) => {
	switch (props.type) {
		case 1:
			return <LogoImg src={logo1} />;
		case 2:
			return <LogoImg src={logo2} />;
		case 3:
			return <LogoImg src={logo3} />;
	}
};

export default Logo;
