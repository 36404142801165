import { OptionType } from '../components/Select';
import { CarModel, CarnoonModel, CarnoonPriceData } from '../firebase/app';
import { BaseJSON } from '../types/common';
import { Nullable } from '../types/utils';
import { noneSpacingStr, pipeStrFn, uppercaseStr } from './functional';

/**
 * BaseJSON 배열을 OptionType 배열로 변환하여 반환한다..
 */
export const getOptions: (l: (BaseJSON | CarModel)[]) => OptionType[] = (l) =>
	l.map((v) => ({
		name: v.name,
		value: `${v.id}`,
		category: v.category
	}));

/**
 * optionType 리스트에서 주어진 id에 name(string)을 찾아 반환한다.
 */
export const getNameById: (l: OptionType[], id: string) => string = (l, id) =>
	l.find((v) => v.value === id)?.name ?? '';

/**
 * optionType 리스트에서 주어진 id에 category(string)을 찾아 반환한다.
 */
export const getCategoryById: (l: OptionType[], id: string) => string = (
	l,
	id
) => l.find((v) => v.value === id)?.category ?? '';

/**
 * @param
 * @returns YYYYMMDD | YYYYMMDDhhmmss(isFull)
 */
type GetDateStr = (date: Date, options?: { isFull?: boolean }) => string;
export const getDateStr: GetDateStr = (date, options) => {
	const y = date.getFullYear();
	const m = `${date.getMonth() + 1}`.padStart(2, '0');
	const d = `${date.getDate()}`.padStart(2, '0');

	const hh = options?.isFull ? `${date.getHours()}`.padStart(2, '0') : '';
	const mm = options?.isFull ? `${date.getMinutes()}`.padStart(2, '0') : '';
	const ss = options?.isFull ? `${date.getSeconds()}`.padStart(2, '0') : '';
	return y + m + d + hh + mm + ss;
};

/**
 * 기준 모델명과 매칭시켜 가격 데이터를 생성하여 반환한다.
 */
export const matchCarnoonData: (p: {
	stdModelName: string;
	carnoonModelsData: CarnoonModel[];
}) => Nullable<CarnoonModel> = (p) => {
	const stdModelName = p.stdModelName;

	let f: Nullable<CarnoonModel> = null;
	const getNoneSpacingStr = pipeStrFn(noneSpacingStr);
	const getUppercaseStr = pipeStrFn(uppercaseStr);
	for (const k in p.carnoonModelsData) {
		const v = p.carnoonModelsData[k];
		if (getNoneSpacingStr(stdModelName).includes(getNoneSpacingStr(v.title))) {
			f = v;
			break;
		}
	}

	if (f) {
		const matchingValues: {
			name: string[];
			subTitle: string[];
		} = {
			name: [],
			subTitle: []
		};

		const checkIncludeReducer = (
			d: CarnoonPriceData,
			target: 'name' | 'subTitle'
		) => {
			const targetValue = getNoneSpacingStr(d[target]);

			return (a: boolean, c: string) => {
				const r = getUppercaseStr(targetValue).includes(getUppercaseStr(c));

				if (
					r &&
					!matchingValues[target]
						.map(getUppercaseStr)
						.includes(getUppercaseStr(c))
				) {
					matchingValues[target].push(c);
				}
				return a ? a : r;
			};
		};

		const splited = stdModelName.split(' ');

		let datas = f.datas.filter((v) => {
			return (
				splited.reduce<boolean>(checkIncludeReducer(v, 'name'), false) &&
				splited.reduce<boolean>(checkIncludeReducer(v, 'subTitle'), false)
			);
		});

		if (datas.length === 0 || datas.length > 1) {
			datas = f.datas
				.filter((v) => {
					return (
						splited.reduce<boolean>(checkIncludeReducer(v, 'name'), false) ||
						splited.reduce<boolean>(checkIncludeReducer(v, 'subTitle'), false)
					);
				})
				// 여러개의 단어가 매칭될 경우 잘못된 단어가 포함될 수 있으므로 여러 단어가 매칭되는 데이터는 제거한다.
				.filter((v) => {
					const name = v.name.replace(/ /g, '');
					let nameIncludedCnt = 0;
					for (const i in splited) {
						if (getUppercaseStr(name).includes(getUppercaseStr(splited[i]))) {
							nameIncludedCnt++;
						}
					}

					const subTitle = v.subTitle.replace(/ /g, '');
					let subTitleIncludedCnt = 0;
					for (const i in splited) {
						if (
							getUppercaseStr(subTitle).includes(getUppercaseStr(splited[i]))
						) {
							subTitleIncludedCnt++;
						}
					}
					return (
						nameIncludedCnt === matchingValues.name.length &&
						subTitleIncludedCnt === matchingValues.subTitle.length
					);
				});
		}
		f.datas = datas;
	}

	return f;
};

export const each = <T>(
	values: Record<string, T>,
	callbackFn: (val: T, key?: string) => void
): void => {
	for (const key in values) {
		callbackFn(values[key], key);
	}
};

export const toArray = <T>(obj: Record<string, T>): T[] => {
	const result: T[] = [];
	each<T>(obj, (val) => {
		result.push(val);
	});
	return result;
};
