import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
	Box,
	FlexItem,
	HBox,
	HeadLine,
	Label,
	SubHeadLine,
	Text,
	VBox
} from '../components/common';
import Layout from '../components/Layout';
import Select, { OnChangeSelect, OptionType } from '../components/Select';
import { useDisplayCarModels } from '../hooks/queries/useDisplayCarModels';
import { useDisplayManufacturers } from '../hooks/queries/useDisplayManufacturers';

const Highlight = styled(HBox)`
	z-index: -1;
	display: inline;
	box-shadow: inset 0 -8px 0 #d9fcdb;
	transition: 0.2s all;
`;

const getDefaultManufacturer = () => {
	const usp = new URLSearchParams(location.search);
	return usp.get('manufacturer_id') ?? '';
};

const getDefaultCarModel = () => {
	const usp = new URLSearchParams(location.search);
	return usp.get('model_id') ?? '';
};

const CarModelSelect: React.FC<{}> = (_) => {
	const location = useLocation();
	const navigate = useNavigate();

	const urlSearchParams = useMemo(() => {
		const { search } = location;
		return new URLSearchParams(search);
	}, [location]);

	const { data: manufacturers, isLoading: isLoadingManufacturers } =
		useDisplayManufacturers();

	const manufacturerOptions = useMemo<OptionType[]>(() => {
		if (isLoadingManufacturers) return [];
		if (!manufacturers) return [];

		return manufacturers.map((manufacturer) => ({
			value: `${manufacturer.display_manufacturer_id}`,
			name: manufacturer.display_manufacturer_name
		})) as OptionType[];
	}, [manufacturers, isLoadingManufacturers]);

	const [selectedManufacturer, setSelectedManufacturer] = useState<string>(
		getDefaultManufacturer()
	);

	const { data: carModels, isLoading: isLoadingCarModel } = useDisplayCarModels(
		parseInt(selectedManufacturer)
	);

	const carModelOptions = useMemo<OptionType[]>(() => {
		if (isLoadingCarModel) return [];
		if (!carModels) return [];

		return carModels.map((manufacturer) => ({
			value: `${manufacturer.display_model_id}`,
			name: manufacturer.display_model_name
		})) as OptionType[];
	}, [carModels, isLoadingCarModel]);

	const [selectedCarModel, setSelectedCarModel] = useState<string>(
		getDefaultCarModel()
	);

	const [inited, setInited] = useState(false);

	const handleChangeManufacturer: OnChangeSelect = (val) => {
		setSelectedManufacturer(val);
		if (inited) {
			setSelectedCarModel('');
		}
	};

	useEffect(() => {
		const { pathname, search } = location;
		const urlSearchParams = new URLSearchParams(search);

		urlSearchParams.set('manufacturer_id', selectedManufacturer);

		navigate(`${pathname}?${urlSearchParams.toString()}`, {
			replace: true
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedManufacturer]);

	const handleChangeCarModel: OnChangeSelect = (val) => {
		setSelectedCarModel(val);
	};

	useEffect(() => {
		const { pathname, search } = location;
		const urlSearchParams = new URLSearchParams(search);

		urlSearchParams.set('model_id', selectedCarModel);

		navigate(`${pathname}?${urlSearchParams.toString()}`, {
			replace: true
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCarModel]);

	const carModelName = useMemo(() => {
		const name =
			carModelOptions?.find((carModel) => carModel.value === selectedCarModel)
				?.name ?? '';

		return name;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCarModel, carModelOptions]);

	useEffect(() => {
		if ((manufacturerOptions.length > 0, carModelOptions.length > 0)) {
			setInited(true);
		}

		if (getDefaultCarModel() === '' && getDefaultManufacturer() === '') {
			setInited(true);
		}
	}, [manufacturerOptions, carModelOptions]);

	return (
		<>
			<Layout
				transition
				footerButtonOptions={{
					gaId: 'btn-carmodel-next',
					to: `/region?${urlSearchParams.toString()}`,
					text: '다음단계',
					disabled: !selectedCarModel
				}}
			>
				<VBox $py={16} $px={25}>
					<Box $mt={20} $textAlign="left" $width="100%">
						<HeadLine $variant="h5" $mb={12}>
							<Text $variant="p2">🚙</Text> 전기차를 선택해주세요.
						</HeadLine>
						<SubHeadLine $variant="p2" $mb={30}>
							옵션별로 모델명이 상이하므로 한번 더 확인해주세요.
						</SubHeadLine>
						<HBox $mt={30}>
							<FlexItem $flex="1 1 30%">
								<VBox $mr={16}>
									<Label $mb={8}>제조사</Label>
									<Select
										defaultValue={getDefaultManufacturer()}
										options={manufacturerOptions}
										onChange={handleChangeManufacturer}
									></Select>
								</VBox>
							</FlexItem>
							<FlexItem $flex="1 1 50%">
								<VBox>
									<Label $mb={8}>모델명</Label>
									<Select
										defaultValue={getDefaultCarModel()}
										options={carModelOptions}
										onChange={handleChangeCarModel}
									></Select>
								</VBox>
							</FlexItem>
						</HBox>
						<VBox $mt={24}>
							{carModelName && (
								<Text
									$variant="p2"
									$color="dark02"
									$lineHeight={1.3}
									$textAlign="left"
								>
									✔️ <Highlight>{carModelName}</Highlight>를 선택하셨습니다.
								</Text>
							)}
						</VBox>
					</Box>
				</VBox>
				{/* <CoupangAds></CoupangAds> */}
			</Layout>
		</>
	);
};

export default CarModelSelect;
