import React, { useContext } from 'react';
import { DispatchContext, StateContext } from '../App';
import { Action, State } from '../modules';

export default function useStore(): {
	state: State;
	dispatch: React.Dispatch<Action>;
} {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);

	return {
		state,
		dispatch
	};
}
