import React from 'react';

type Props = React.PropsWithChildren<{}>;
type State = { hasError: boolean };

export default class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(_error: Error): State {
		return { hasError: true };
	}

	render(): React.ReactNode {
		if (this.state.hasError) {
			return <p>Loading failed! Please reload.</p>;
		}

		return this.props.children;
	}
}
