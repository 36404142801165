import React from 'react';
import styled, { keyframes } from 'styled-components';
import { VBox, Text } from './common';
import Layout from './Layout';

const boxFade = keyframes`
	30% { transform: scale(1.2); }
	40%, 60% { transform: rotate(-20deg) scale(1.2); }
	50% { transform: rotate(20deg) scale(1.2); }
	70% { transform: rotate(0deg) scale(1.2); }
	100% { transform: scale(1); }
`;

const AnimationBox = styled(VBox)`
	font-family: Pretendard Variable, Pretendard;
	font-size: 40px;
	animation: ${boxFade} 1s ease infinite;
`;

const LoadingContent: React.FC<{}> = (_) => {
	return (
		<Layout>
			<VBox $justifyContent="center" $alignItems="center" $mt={200}>
				<AnimationBox>🚙⚡</AnimationBox>
				<VBox $mt={24}>
					<Text $variant="p3" $lineHeight="1.2" $bold>
						결과를 조회 중입니다. 잠시만 기다려주세요.
					</Text>
				</VBox>
			</VBox>
		</Layout>
	);
};

export default LoadingContent;
