import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { Region } from '../../interfaces/Region';
import { toArray } from '../../utils';
import { equalTo, orderByChild, query } from 'firebase/database';

export function useRegion(region_id: number): UseQueryResult<Region, unknown> {
	return useQuery({
		queryKey: ['region', region_id],
		queryFn: async () =>
			await fetchData<Record<string, Region> | null>('/regions', (ref) => {
				return query(ref, orderByChild('region_id'), equalTo(region_id));
			})
				.then((values) => values ?? {})
				.then((values) => toArray<Region>(values))
				.then((values) => (values ? values[0] : null))
	});
}
