import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, TransparentButton } from './common';
import ArrowRight from '../assets/icons/arrow-right.svg';

const BackButton: React.FC<{}> = () => {
	const navigate = useNavigate();

	const handleClickBack = () => {
		navigate(-1);
	};

	return (
		<TransparentButton $py={12} onClick={handleClickBack}>
			<Text $variant="h5">
				<img src={ArrowRight} alt="뒤로가기" />
			</Text>
		</TransparentButton>
	);
};

export default BackButton;
