import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingContent from '../components/LoadingContent';
import useEncryptData from '../hooks/useEncryptData';

const ResultLink: React.FC<{}> = (_) => {
	const location = useLocation();
	const navigate = useNavigate();

	const { setEncryptData } = useEncryptData();

	useEffect(() => {
		const usp = new URLSearchParams(location.search);
		const d = usp.get('d');
		if (d === null) {
			navigate('/');
			return;
		}

		setEncryptData(d);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <LoadingContent />;
};

export default ResultLink;
