import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexBox } from './common';
export type OptionType = {
	value: string;
	name: string;
	category: string;
};

export type OnChangeSelect = (val: string) => void;

const CustomSelect = styled.select`
	margin: 0;
	min-width: 0;
	display: block;
	width: 100%;
	padding: 16px 30px 16px 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 16px;
	line-height: inherit;
	border: 1px solid ${(props) => props.theme.Colors.dark};
	border-radius: 12px;
	color: ${(props) => props.theme.Colors.dark};
	background-color: transparent;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAeJJREFUaEPt1b+rz1EYB/DXFckgkUnZDAZlJT8yiaLczWYxsGNQFinFH0DJpEw2BhkshMGiDDaDUhaSDH5Fj763rpvrPJ/POZ9063zW8zzv5/3jfJ4zZ4V/cyucvy7gfyfYE+gJVDrQr1ClgdXtPYFqCysBegKVBla39wSqLawEyCZwCWewtnJetv0LruJCqSEr4D02lsAan3/AphJmVsAVnC2BNT6PBM6VMLMCVuEO5kuAjc7v4Rh+lPCyAgJnHR5iVwm08vw5DuBzBmeIgMDbjKfYlgEfUfMau/Eu2ztUQOBux5MJfupYFHvwKks+6sYIiL79eNBwrX7F4dkVHcJ/tIAYchy3K0xYIPoTJ3BrEPNZ8dgEFmbFQ3NxzOBFPedxeSxGrYCYew2nRhK4iZMje3+3tRCwBrG3Dw4kch9H8X1g3x/lLQQE4Ho8ws4kmZfYi4/J+mXLWgmIAVvwDFsLpN7OHsM3teRbXaHFPHbgMTYsQ+4T9uFFC/JTCAjMQ7iL1UtIfsOR2fvRin+Tn/hvZGKz3FhycBrXmzFv9A78i0+s1tjx8VDFWxErs/nX8iduTi4D2AVkXJqypicwpbsZ7J5AxqUpa3oCU7qbwe4JZFyasqYnMKW7GeyeQMalKWt+AaigMTEVPMvsAAAAAElFTkSuQmCC');
	background-repeat: no-repeat;
	background-position: right 16px bottom 16px;
	background-size: 18px 18px;
	&:focus {
		border-color: ${(props) => props.theme.Colors.primary05};
	}
`;

const Select: React.FC<{
	options: OptionType[];
	onChange?: OnChangeSelect;
	defaultValue?: string;
}> = (props) => {
	const [value, setValue] = useState<string>('');

	const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
		if (props.onChange) {
			const val = e.target.value;
			setValue(val);
			props.onChange(val);
		}
	};

	useEffect(() => {
		if (props.defaultValue) {
			setValue(props.defaultValue);
		}
	}, [props.defaultValue]);

	return (
		<FlexBox $width="100%">
			<CustomSelect onChange={handleChange} value={value}>
				<option value="">선택해주세요</option>
				{props.options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.name}
					</option>
				))}
			</CustomSelect>
		</FlexBox>
	);
};

export default Select;
