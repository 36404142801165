import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { State } from '../modules';
import { ValueOf } from '../types/utils';
import useStore from './useStore';

export default function useEncryptData(): {
	getEncryptStr: () => string;
	setEncryptData: (encryptStr: string) => void;
} {
	const { state, dispatch } = useStore();
	const navigate = useNavigate();

	const [_encrypted, _setEncrypted] = useState<string>('');
	const [_decrypted, _setDecrypted] = useState<string>('');

	const getEncryptStr = useCallback(() => {
		const { formData, textData } = state;

		// const data: Data = {
		// 	formData,
		// 	textData
		// };

		const arr: string[] = [
			formData.regionId,
			formData.sigunguId,
			formData.manufacturerId,
			formData.carModelId,
			textData.region,
			textData.sigungu,
			textData.manufacturer,
			textData.carModel,
			textData.category
		];

		return encodeURI(JSON.stringify(arr).replace(/\%22/g, '$'));
	}, [state]);

	const setEncryptData = useCallback(
		(encryptStr: string) => {
			try {
				const data: string[] = JSON.parse(decodeURIComponent(encryptStr));

				const isInvalied = !(
					Array.isArray(data) &&
					data.reduce<boolean>((a, c) => {
						return !a ? a : typeof c === 'string';
					}, true)
				);

				if (isInvalied) {
					throw Error('invalied data');
				}

				type FormData = ValueOf<Pick<State, 'formData'>>;
				const formData: FormData = {
					regionId: data[0],
					sigunguId: data[1],
					manufacturerId: data[2],
					carModelId: data[3]
				};
				dispatch({
					type: 'formdata/set',
					payload: JSON.stringify(formData)
				});

				type TextData = ValueOf<Pick<State, 'textData'>>;
				const textData: TextData = {
					region: data[4],
					sigungu: data[5],
					manufacturer: data[6],
					carModel: data[7],
					category: data[8]
				};
				dispatch({
					type: 'textdata/set',
					payload: JSON.stringify(textData)
				});

				navigate('/result');
			} catch (e) {
				navigate('/');
			}
		},
		[dispatch, navigate]
	);

	return {
		getEncryptStr,
		setEncryptData
	};
}
