import React, { useEffect, useReducer } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from './pages/LoadingAds';
// import NotFound from './pages/NotFound';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ogImg from '../src/assets/evcaro-og2.png';
import Header from './components/Header';
import reducer, { Action, initialState, State } from './modules';
import CarModelSelect from './pages/CarModelSelect';
import RegionSelect from './pages/RegionSelect';
import ResultLink from './pages/ResultLink';
import ResultPage from './pages/ResultPage';
import Splash from './pages/Splash';
import Test from './pages/Test';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

export const DispatchContext = React.createContext<React.Dispatch<Action>>(
	(_a: Action) => {
		// pass
	}
);
export const StateContext = React.createContext<State>(initialState);

const MetaTag: React.FC<{
	options?: {
		title?: string;
		description?: string;
		keywords?: string;
		imgSrc?: string;
		url?: string;
	};
}> = (props) => {
	// props로 content 내용을 불러올 예정임
	return (
		<Helmet
			title={props.options?.title}
			meta={[
				{ name: 'description', content: props.options?.description },
				{ name: 'keywords', content: props.options?.keywords },

				{ property: 'og:type', content: 'website' },
				{ property: 'og:title', content: props.options?.title },
				{ property: 'og:site_name', content: props.options?.title },
				{ property: 'og:description', content: props.options?.description },
				{ property: 'og:image', content: props.options?.imgSrc },
				{ property: 'og:url', content: props.options?.url },

				{ name: 'twitter:title', content: props.options?.title },
				{ name: 'twitter:description', content: props.options?.description },
				{ name: 'twitter:image', content: props.options?.imgSrc }
			]}
		>
			<link rel="canonical" href={props.options?.url} />
		</Helmet>
	);
};

function App(): React.ReactElement {
	const [state, dispatch] = useReducer(reducer, initialState);
	useEffect(() => {
		TagManager.initialize({ gtmId: 'GTM-PFRBS27' });
	}, []);
	return (
		<DispatchContext.Provider value={dispatch}>
			<StateContext.Provider value={state}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<HelmetProvider>
							<MetaTag
								options={{
									title: 'EVCARO',
									description:
										'2023년 지역별 전기차 보조금, 잔여대수, 예상 차 가격을 10초만에 확인해보세요!',
									url: 'https://evcaro.com',
									imgSrc: ogImg,
									keywords:
										'전기차보조금, 누리집, 2023 전기차 보조금, 전기차 보조금 현황, 2023년 전기차 보조금, 아이오닉6 보조금, 아이오닉5 보조금, 테슬라 보조금, ev6 보조금, ev9 보조금, 서울시 전기차 보조금, 경기도 전기차 보조금'
								}}
							/>
							<Header />
							<Routes>
								{/* <Header></Header> */}
								<Route path="/" element={<Splash />} />
								<Route path="/car-model" element={<CarModelSelect />} />
								<Route path="/region" element={<RegionSelect />} />
								<Route path="/loading" element={<Loading />} />
								<Route path="/result" element={<ResultPage />} />
								<Route path="/result-link" element={<ResultLink />} />
								{/* <Route element={<NotFound />} /> */}
								<Route path="/test" element={<Test />} />
							</Routes>
						</HelmetProvider>
					</BrowserRouter>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</StateContext.Provider>
		</DispatchContext.Provider>
	);
}

export default App;
