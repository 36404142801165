import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';
import { fetchData } from '../../firebase/app';
import { DisplayCarModel } from '../../interfaces/DisplayCarModel';
import { equalTo, orderByChild, query } from 'firebase/database';
import { toArray } from '../../utils';

export function useDisplayCarModels(
	display_manufacturer_id: number
): UseQueryResult<DisplayCarModel[], unknown> {
	return useQuery(
		['display_car_models', display_manufacturer_id],
		async () =>
			await fetchData<Record<string, DisplayCarModel> | null>(
				'/display_car_models',
				(ref) => {
					return query(
						ref,
						orderByChild('display_manufacturer_id'),
						equalTo(display_manufacturer_id)
					);
				}
			)
				.then((values) => values ?? {})
				.then((values) => toArray<DisplayCarModel>(values)),
		{
			enabled: !!display_manufacturer_id
		}
	);
}
